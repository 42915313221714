import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { PropertyToJson2 } from '@nest/common/core/shared';
import { ButtonComponent } from '@nest/uikit/button/ui';
import { provideIcons } from '@ng-icons/core';
import { iconoirCopy, iconoirOpenInBrowser } from '@ng-icons/iconoir';

@Component({
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [ButtonComponent],
	providers: [provideIcons({ iconoirCopy, iconoirOpenInBrowser })],
	template: `
		<div class="whitespace-nowrap overflow-hidden text-ellipsis">
			@if (googleMapsLink()) {
				<a (click)="handleLinkClick($event)" nest [href]="googleMapsLink()" target="_blank">{{ presentationValue() }}</a>
			} @else {
				{{ presentationValue() }}
			}
		</div>
	`
})
export class GpsPathRendererComponent {
	value = input.required<{ lat: number; lng: number }>();
	entityId = input.required<number>();

	readonly presentationValue = computed(() => {
		if (this.value()) {
			return `Lat: ${this.value().lat}, Long: ${this.value().lng}`;
		}

		return '';
	});

	readonly googleMapsLink = computed(() => {
		if (this.value()) {
			return `https://www.google.com/maps/place/${this.value().lat},${this.value().lng}`;
		}

		return '';
	});

	propertyMetadata = input.required<PropertyToJson2>();

	handleLinkClick(event: MouseEvent) {
		event.stopPropagation();
	}
}
