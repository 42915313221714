import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { PropertyToJson2 } from '@nest/common/core/shared';
import { provideIcons } from '@ng-icons/core';
import { iconoirCopy, iconoirOpenInBrowser } from '@ng-icons/iconoir';
import { DateTime } from 'luxon';

@Component({
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [],
	providers: [provideIcons({ iconoirCopy, iconoirOpenInBrowser })],
	template: `
		<div>
			@for (timeWindow of presentationValue(); track timeWindow.from; let last = $last) {
				{{ timeWindow.from }} - {{ timeWindow.to }}
				@if (!last) {
					,&nbsp;
				}
			}
		</div>
	`
})
export class TimeWindowPathRendererComponent {
	value = input.required<{ from: number; to: number }[]>();
	entityId = input.required<number>();

	readonly presentationValue = computed(
		() =>
			this.value()?.map(({ from, to }) => {
				const fromTime = DateTime.fromSeconds(from).toUTC().toFormat('HH:mm');
				const toTime = DateTime.fromSeconds(to).toUTC().toFormat('HH:mm');

				return {
					from: fromTime,
					to: toTime
				};
			}) || []
	);

	propertyMetadata = input.required<PropertyToJson2>();
}
