export enum OutboundDocumentCompletationStrategy {
	Invoice = 'Invoice',
	Valuation = 'Valuation',
	Confirm = 'Confirm'
}

export enum OutboundDocumentConfirmationStrategy {
	None = 'None',
	ConfirmRequired = 'ConfirmRequired'
}

export interface SaveQuery {
	skipWarnings: boolean;
}

export interface ToCorrectionDocumentBody {
	originalId: number;
	populate?: string[];
}

export interface SendtoWmsBody {
	skipWarnings?: boolean;
}

export interface SendOutboundDocumentBody {
	recipientEmail: string;
	message: string;
	subject: string;
}

export enum OutboundDocumentState {
	Created = 'Created',
	SentToWms = 'SentToWms',
	CompletedInWms = 'CompletedInWms',
	CanceledInWms = 'CanceledInWms',
	Dispatched = 'Dispatched',
	Canceled = 'Canceled',
	/** @deprecated Use it for backward compatibility only. Use for new entries OutboundDocumentState.CompletedInWms */
	Completed = 'Completed'
}
