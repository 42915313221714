import { ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';
import { PropertyToJson2 } from '@nest/common/core/shared';
import { OutboundDocumentState } from '@nest/domain/outbound-documents/shared';
import { Entity, EntityValuePipe } from '@nest/uikit/entity-presentation/ui';

@Component({
	standalone: true,
	template: `{{ propertyMetadata()?.enumValues[value()] }}`,
	styles: `
		:host {
			@apply inline-block p-1 px-2 rounded-md text-ellipsis overflow-hidden w-full text-center text-nowrap;
		}
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [EntityValuePipe]
})
export class OutboundDocumentStateColorEntityPathRendererComponent<E extends Entity> {
	value = input.required<OutboundDocumentState>();
	entityId = input.required<number>();
	propertyMetadata = input.required<PropertyToJson2>();

	@HostBinding('class.bg-red-100') @HostBinding('class.text-red-800') get created() {
		return this.value() == OutboundDocumentState.Created;
	}

	@HostBinding('class.bg-orange-100') @HostBinding('class.text-orange-800') get inProgress() {
		return this.value() == OutboundDocumentState.SentToWms;
	}

	@HostBinding('class.bg-green-100') @HostBinding('class.text-green-800') get completed() {
		return this.value() == OutboundDocumentState.Dispatched;
	}

	@HostBinding('class.bg-blue-100') @HostBinding('class.text-blue-800') get sentToWms() {
		return this.value() == OutboundDocumentState.CompletedInWms;
	}

	@HostBinding('class.bg-slate-200') get canceled() {
		return this.value() == OutboundDocumentState.Canceled;
	}
}
